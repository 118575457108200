import { CALL_API } from '../../middleware/api';
import { convertObjectToQueryString } from '../../utilities/urlUtils';

export const GET_LOCATIONS_REQUEST = 'GET_LOCATIONS_REQUEST';
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS';
export const GET_LOCATIONS_FAILURE = 'GET_LOCATIONS_FAILURE';
export const SEARCH_LOCATIONS_REQUEST = 'SEARCH_LOCATIONS_REQUEST';
export const SEARCH_LOCATIONS_SUCCESS = 'SEARCH_LOCATIONS_SUCCESS';
export const SEARCH_LOCATIONS_FAILURE = 'SEARCH_LOCATIONS_FAILURE';
export const SEARCH_NEARBY_LOCATIONS_REQUEST = 'SEARCH_NEARBY_LOCATIONS_REQUEST';
export const SEARCH_NEARBY_LOCATIONS_SUCCESS = 'SEARCH_NEARBY_LOCATIONS_SUCCESS';
export const SEARCH_NEARBY_LOCATIONS_FAILURE = 'SEARCH_NEARBY_LOCATIONS_FAILURE';
export const SET_SEARCH_FILTERS = 'SET_SEARCH_FILTERS';
export const CLEAR_SEARCH_FILTERS = 'CLEAR_SEARCH_FILTERS';
export const CLEAR_LOCATIONS = 'CLEAR_LOCATIONS';

export const getLocations = () => {
  return {
    [CALL_API]: {
      types: [GET_LOCATIONS_REQUEST, GET_LOCATIONS_SUCCESS, GET_LOCATIONS_FAILURE],
      authenticated: true,
      endpoint: 'locations',
      method: 'GET',
    },
  };
};

export const clearLocations = () => {
  return {
    type: CLEAR_LOCATIONS,
  };
};

export const setSearchFilters = (searchFilters) => {
  return {
    type: SET_SEARCH_FILTERS,
    searchFilters,
  };
};

export const clearSearchFilters = () => {
  return {
    type: CLEAR_SEARCH_FILTERS,
  };
};

export const getLocationsBySearchCriteria = searchCriterias => {
  const queryStringParams = convertObjectToQueryString(searchCriterias);
  return {
    [CALL_API]: {
      types: [SEARCH_LOCATIONS_REQUEST, SEARCH_LOCATIONS_SUCCESS, SEARCH_LOCATIONS_FAILURE],
      authenticated: true,
      endpoint: `locations/search?${queryStringParams}`,
      method: 'GET',
    },
  };
};

export const searchNearbyLocations = ({longitude, latitude, radius}) => {
  return {
    [CALL_API]: {
      types: [SEARCH_NEARBY_LOCATIONS_REQUEST, SEARCH_NEARBY_LOCATIONS_SUCCESS, SEARCH_NEARBY_LOCATIONS_FAILURE],
      authenticated: true,
      endpoint: `locations/search?longitude=${longitude}&latitude=${latitude}&radius=${radius}`,
      method: 'GET',
    },
  };
};
