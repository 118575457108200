import { Button, Grid, IconButton, TextField, Typography } from '@mui/material';
import { compose } from 'redux';
import { useDispatch } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import withStyles from '@mui/styles/withStyles';

import { handleToastMessage } from '../../../layout/layout.actions';
import { parseAddress } from '../../../../utilities/googleMapsUtils';
import Colors from '../../../../styles/colors';
// import GooglePlaceSection from './googlePlaceSection.component';
import * as _ from 'lodash';
import { SEARCH_NEARBY_LOCATIONS_SUCCESS, searchNearbyLocations } from '../../../locations/locations.actions';
import Map from './map.component';
import MapDialog from './map.dialog';
import copyToClipboard from '../../../../utilities/copyToClipboard';
function MapSection({ classes, selectedLocation, disabled, onChange }) {
  const dispatch = useDispatch();

  // UNCOMMENT TO ENABLE GOOGLE PLACE LOOKUP
  // const [googlePlace, setGooglePlace] = useState({});
  const [openMapDialog, setOpenMapDialog] = useState(false);
  const [latLongReadOnly, setLatLongReadOnly] = useState(true);
  const [nearbyLocations, setNearbyLocations] = useState([]);

  const copyLocation = (location) => {
    copyToClipboard(`${location.podLatitude},${location.podLongitude}`);
    dispatch(handleToastMessage('Copied!'));
  };

  const fetchNearbyLocations = async () => {
    const { latitude, longitude } = selectedLocation;
    const updatedNearbyLocations = await dispatch(searchNearbyLocations({ latitude, longitude, radius: 5000 }));
    if (updatedNearbyLocations.type === SEARCH_NEARBY_LOCATIONS_SUCCESS && updatedNearbyLocations?.response) {
      setNearbyLocations(updatedNearbyLocations.response);
    }
  };

  const onRefineClick = async () => {
    setOpenMapDialog(true);
    fetchNearbyLocations();
  };

  const hasPodCoordinates = (location) => {
    return location.podLatitude !== null && location.podLatitude !== undefined &&
      location.podLongitude !== null && location.podLongitude !== undefined;
  };

  const roundCoord = (num, roundedDecimalPosition) => {
    if (typeof num !== 'number') {
      return _.random();
    }
    return Number(num.toFixed(roundedDecimalPosition));
  };

  const getPositionGroupedLocations = (locations, roundedDecimalPosition = 4) => {
    return _.chain(locations)
      .groupBy((location) => `${roundCoord(location.latitude, roundedDecimalPosition)},${roundCoord(location.longitude, roundedDecimalPosition)}`)
      .map((group, key) => {
        const [lat, lng] = key.split(',').map(Number);
        return {
          numOfLocations: group.length,
          latitude: lat,
          longitude: lng,
          locations: group.map((location) => ({ 
            id: location.id, 
            name: `${location.name}${location?.podDeviceSerial ? ` (${location.podDeviceSerial})` : ''}` })),
        };
      })
      .value();
  };

  const updateGooglePlaceId = (googlePlaceId) => {
    onChange({
      target: {
        name: 'googlePlaceId',
        value: googlePlaceId,
      },
    });
  };

  const handleMapUpdate = async (latitude, longitude, address, googlePlaceId) => {
    await onChange({
      target: {
        name: 'latitude',
        value: latitude,
      },
    });
    await onChange({
      target: {
        name: 'longitude',
        value: longitude,
      },
    });

    if (address) {
      const parsedAddress = parseAddress(address);
      await onChange({
        target: {
          name: 'addressOne',
          value: parsedAddress.addressOne,
        },
      });
      await onChange({
        target: {
          name: 'addressTwo',
          value: parsedAddress.addressTwo,
        },
      });
      await onChange({
        target: {
          name: 'city',
          value: parsedAddress.city,
        },
      });
      await onChange({
        target: {
          name: 'state',
          value: parsedAddress.state,
        },
      });
      await onChange({
        target: {
          name: 'zip',
          value: parsedAddress.zip,
        },
      });
    }

    if (googlePlaceId) {
      updateGooglePlaceId(googlePlaceId);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">Mapping</Typography>
      </Grid>
      <Grid item xs={12}>
        {selectedLocation.latitude && selectedLocation.longitude && (
          <Map
            isMarkerShown
            latitude={selectedLocation.latitude}
            longitude={selectedLocation.longitude}
            onClick={() => { }}
          />
        )}
        {(!selectedLocation.latitude || !selectedLocation.longitude) && (
          <Typography>MISSING LATITUDE OR LONGITUDE VALUES</Typography>
        )}
      </Grid>

      <Grid item xs={4}>
        <TextField
          name="latitude"
          label="Latitude"
          inputProps={{ maxLength: 100, readOnly: latLongReadOnly }}
          value={selectedLocation.latitude}
          onChange={onChange}
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="longitude"
          label="Longitude"
          inputProps={{ maxLength: 100, readOnly: latLongReadOnly }}
          value={selectedLocation.longitude}
          onChange={onChange}
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item xs={4}>
        <IconButton
          color={latLongReadOnly ? 'primary' : 'default'}
          onClick={() => setLatLongReadOnly(!latLongReadOnly)}
          disabled={disabled}
          size="large">
          <EditIcon />
        </IconButton>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => onRefineClick()}
          disabled={disabled}
        >
          Refine
        </Button>
      </Grid>

      {hasPodCoordinates(selectedLocation) &&
        <Fragment>
          <Grid item xs={4}>
            <TextField
              id="reportedLatitude"
              label="Reported Latitude"
              inputProps={{ maxLength: 100, readOnly: true }}
              disabled
              value={selectedLocation.podLatitude}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="reportedLongitude"
              label="Reported Longitude"
              inputProps={{ maxLength: 100, readOnly: true }}
              disabled
              value={selectedLocation.podLongitude}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => copyLocation(selectedLocation)}
            >
              Copy
            </Button>
          </Grid>
        </Fragment>
      }

      {/* UNCOMMENT TO ENABLE GOOGLE PLACE LOOKUP */}
      {/* <GooglePlaceSection
        selectedLocation={selectedLocation}
        setGooglePlace={setGooglePlace}
        updateGooglePlaceId={updateGooglePlaceId}
        disabled={disabled}
        googlePlace={googlePlace}
      /> */}

      <MapDialog
        textContent={`There are ${nearbyLocations.length +1} locations in the map`}
        secondaryMarkers={getPositionGroupedLocations(nearbyLocations, 8)}
        handleUpdate={handleMapUpdate}
        handleClose={() => {
          setOpenMapDialog(false);
        }}
        isOpen={openMapDialog}
        latitude={selectedLocation.latitude}
        longitude={selectedLocation.longitude}
      />
    </Grid>
  );
}

const styles = () => ({
  formLabel: {
    fontSize: 12,
  },
  latLongWrapper: {
    paddingTop: 5,
    paddingBottom: 0,
    display: 'flex',
    flexWrap: 'nowrap',
  },
  googlePlaceContainer: {
    fontFamily: 'GothamRounded-Bold',
    color: Colors.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

MapSection.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedLocation: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,

  onChange: PropTypes.func.isRequired,
};

const prepareForExport = compose(
  withStyles(styles, { withTheme: true }),
);

export default prepareForExport(MapSection);